<template>
    <div class="wrapper">
        <div class="sidebar-stripe">
            <!-- nothing -->
        </div><!-- end sidebar-stripe -->
        <a id="homeBtn" href="/">
            <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.125 5.98438L5.5 13.1094V20.75C5.5 21.1719 5.82812 21.5 6.25 21.5H11.5C11.875 21.5 12.2031 21.1719 12.2031 20.75V16.25C12.2031 15.875 12.5781 15.5 12.9531 15.5H15.9531C16.375 15.5 16.7031 15.875 16.7031 16.25V20.75C16.7031 21.1719 17.0781 21.5 17.4531 21.5H22.75C23.125 21.5 23.5 21.1719 23.5 20.75V13.0625L14.8281 5.98438C14.7344 5.89062 14.5938 5.84375 14.5 5.84375C14.3594 5.84375 14.2188 5.89062 14.125 5.98438ZM27.7656 10.8125L23.875 7.57812V1.10938C23.875 0.78125 23.5938 0.546875 23.3125 0.546875H20.6875C20.3594 0.546875 20.125 0.78125 20.125 1.10938V4.48438L15.9062 1.01562C15.5312 0.734375 15.0156 0.546875 14.5 0.546875C13.9375 0.546875 13.4219 0.734375 13.0469 1.01562L1.1875 10.8125C1.04688 10.9062 0.953125 11.0938 0.953125 11.2344C0.953125 11.375 1.04688 11.5156 1.09375 11.6094L2.3125 13.0625C2.40625 13.2031 2.54688 13.25 2.73438 13.25C2.875 13.25 3.01562 13.2031 3.10938 13.1094L14.125 4.0625C14.2188 3.96875 14.3594 3.92188 14.5 3.92188C14.5938 3.92188 14.7344 3.96875 14.8281 4.0625L25.8438 13.1094C25.9375 13.2031 26.0781 13.25 26.2188 13.25C26.4062 13.25 26.5469 13.2031 26.6406 13.0625L27.8594 11.6094C27.9531 11.5156 28 11.375 28 11.2344C28 11.0938 27.9062 10.9062 27.7656 10.8125Z" fill="#3E4C59"/>
            </svg>
        </a>
        <div class="resume">
            <div class="resume-column">
                <div class="resume-header">
                <!-- <a href="/resume">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-invoice" width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3AE7E1" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                        <line x1="9" y1="7" x2="10" y2="7" />
                        <line x1="9" y1="13" x2="15" y2="13" />
                        <line x1="13" y1="17" x2="15" y2="17" />
                    </svg>
                    <span>Download PDF Resume</span>
                </a> -->
                <h1>Lance Anderson</h1>
                <h3>Fullstack Web Developer and Designer</h3>
                <ResumeAside class="hidden-sm" />
                </div><!-- end header -->
            </div><!-- end resume-column -->
            <div class="experience">
                <h2>Experience</h2>
                <p class="margin-top-12">I have more than fifteen years of experience in the web design and development space, from requirements gathering and refinement, project management and engineering leadership, to design and fullstack development. I enjoy studying how users interact with systems, and designing and developing innovative solutions to enhance their experiences.</p>
                <ol class="connected-items">
                    <li class="company connector">
                        <div class="title-grouping margin-top-24">
                            <h3>BluePath Labs</h3>
                            <span class="years">(September 2021 - Present)</span>
                        </div><!-- end title-grouping -->
                        <ol class="connected-items-sub-list">
                            <li class="inner-connector">
                                <div class="title-grouping">
                                    <h4>Lead Software Engineer</h4> 
                                    <span class="years">(November 2022 - Present)</span>
                                </div><!-- end title-grouping -->
                                <div class="title-grouping">
                                    <h4>Senior Front-End Developer</h4> 
                                    <span class="years">(September 2021 - November 2022)</span>
                                </div><!-- end title-grouping -->
                                <ul class="margin-top-12">
                                    <li>Promoted to Lead Software Engineer for outstanding performance and proven leadership.</li>
                                    <li>Contribute to design and development workstreams on a variety of VueJS web applications.</li>
                                </ul>
                            </li>
                        </ol><!-- end connected-items-sub-list -->
                    </li><!-- end company -->
                    <li class="company connector">
                        <div class="title-grouping">
                            <h3>The Federal Reserve Bank of Cleveland</h3>
                            <span class="years">(April 2015 - September 2021)</span>
                        </div><!-- end title-grouping -->
                        <ol class="connected-items-sub-list">
                            <li class="inner-connector">
                                <div class="title-grouping">
                                    <h4>Software Developer Senior</h4>
                                    <span class="years">(January 2019 - September 2021)</span>
                                </div><!-- end title-grouping -->
                                <div class="title-grouping">
                                    <h4>Software Developer</h4>
                                    <span class="years">(October 2016 - January 2019)</span>
                                </div><!-- end title-grouping -->
                                <div class="title-grouping">
                                    <h4>Business Systems Analyst II - Developer/Web</h4>
                                    <span class="years">(April 2015 - October 2016)</span>
                                </div><!-- end title-grouping -->
                                <ul class="margin-top-12">
                                    <li>Promoted to Software Developer Senior for outstanding performance, demonstrated leadership, and increased technical skill.</li>
                                    <li>Promoted to Software Developer for high performance and demonstrated aptitude.</li>
                                    <li>Create internal web applications, contributing to the entirety of the development process, from UX design, including wireframing and prototyping, UI design and high-fidelity mockups, and writing and deploying front-end code.</li>
                                    <li>Work extensively with HTML, CSS, and JavaScript.</li>
                                    <li>Create high-fidelity, fully-responsive layouts using modern graphic design tools such as Adobe Illustrator, Sketch, and Figma.</li>
                                    <li>Write asynchronous JavaScript code to dynamically load data into the UI and perform business logic as required.</li>
                                    <li>Create applications using VueJS, a modern and reactive JavaScript framework.</li>
                                    <li>Coach and mentor other developers.</li>
                                    <li>Act as project lead, including coordinating Agile sprints and SCRUM ceremonies, and directing the work of others.</li>
                                    <li>Frequently fill the role of Project Manager and manage to timelines, coordinate stakeholder engagement and communication, and facilitate user story creation and backlog refinement.</li>
                                    <li>Lead designer and developer on an application to deliver critical cyber security notices and reports to the Federal Reserve System.</li>
                                    <li>Lead designer and developer of a web application to support the Paycheck Protection Program lending facility on an extremely tight timeline. This application processes thousands of loan applications and associated payments.</li>
                                    <li>Create web applications to deliver critical business intelligence and data analytics dashboards to internal customers. This includes collaborating with Tableau developers to seamlessly embed dashboards in web applications using JavaScript APIs. These applications provide analytical insights to Bank examiners.</li>
                                    <li>Lead designer and developer on multiple System projects, coordinating with resources across multiple Federal Reserve Banks. Projects include a communication and collaboration space for the Large Financial Institution Management Group, an analytics tool for an information security System group, and a System information technology management group.</li>
                                    <li>Lead designer and developer of a Federal Reserve System application to deliver the output of a Natural Language Processing model ran against quarter-end investor relations transcripts. The application allows users to view model insights and targeted results associated with search topics and terms.</li>
                                    <li>Key contributor in a project to create an internal User Experience testing lab, where I worked on the business case and presentation to executive leadership to stress the value of such a space. The project was approved and I continued to work to spin-up a state-of-the-art lab dedicated to UX testing, including the use of eye-tracking software. As a member of this project team, I was a recipient of the Bank’s Spirit of Innovation award.</li>
                                    <li>Developed an in-house web analytics suite to track usage data across a 15 SharePoint sites. This data was used to shape future project planning to better focus our efforts around which applications were getting the most use.</li>
                                    <li>Lead a modernization project to move our development team off of TFS version control and into Git. This included developing best-practices, branching strategies, and all associated documentation. Also created and ran multiple training classes to help train other developers.</li>
                                </ul>
                            </li>
                        </ol><!-- end connected-items-sub-list -->
                    </li><!-- end company -->
                    <li class="company connector">
                        <div class="title-grouping">
                            <h3>DRB Systems</h3>
                            <span class="years">(February 2013 - March 2015)</span>
                        </div><!-- end title-grouping -->
                        <ol class="connected-items-sub-list">
                            <li class="inner-connector">
                                <div class="title-grouping">
                                    <h4>Senior Graphic Designer</h4>
                                </div><!-- end title-grouping -->
                                <ul class="margin-top-12">
                                    <li>Lead designer and head of the media department for the market-leading car wash point of sale company. This included managing a direct report.</li>
                                    <li>Managed a direct report, including directing work, coaching, mentoring, career development, and conducted annual reviews.</li>
                                    <li>Worked on cutting edge consumer-facing electronic UI and touch interfaces.</li>
                                    <li>Transformed the department from an after-thought service add-on to a revenue generating tool. Grew revenue from $81,000 to over $263,000.</li>
                                    <li>Lead business process improvement of all media related order and delivery mechanisms immediately upon arrival. This directly improved fulfillment times and increased customer satisfaction.</li>
                                    <li>Worked directly with customers to build and establish branding and marketing campaigns and associated materials including mailers, billboards, on-site signage, and more.</li>
                                </ul>
                            </li>
                        </ol><!-- end connected-items-sub-list -->
                    </li><!-- end company -->
                    <li class="company connector">
                        <div class="title-grouping">
                            <h3>Goodyear Tire & Rubber Company</h3>
                            <span class="years">(June 2007 - February 2013)</span>
                        </div><!-- end title-grouping -->
                        <ol class="connected-items-sub-list">
                            <li class="inner-connector">
                                <div class="title-grouping">
                                    <h4>Web Designer/Developer</h4>
                                </div><!-- end title-grouping -->
                                <ul class="margin-top-12">
                                    <li>Worked as a front-end web developer – Responsible for designing and building robust, functional web sites for internal corporate use, as well as external customer-facing sites.</li>
                                    <li>Designed the user interface for a customer-facing commercial tire mobile application for iPhone and Android devices.</li>
                                    <li>Developed a web application using JavaScript and XML connected through the Google Maps API to act as a store locator for Goodyear Aviation distributors.</li>
                                </ul>
                                <div class="title-grouping">
                                    <h4 class="margin-top-24">Application Development Specialist</h4>
                                </div><!-- end title-grouping -->
                                <ul class="margin-top-12">
                                    <li>Worked as an ETL developer within the Enterprise Data Warehouse department.</li>
                                    <li>Responsible for entire ETL process from data extraction from SAP, adding business logic to the data flow, and loading the data into the data warehouse.</li>
                                    <li>Developed proficient knowledge in the use of Informatica PowerCenter to perform ETL work.</li>
                                </ul>
                            </li>
                        </ol><!-- end connected-items-sub-list -->
                    </li><!-- end company -->
                </ol>
            </div><!-- end experience -->
            <ResumeAside class="hidden-lg" />
        </div><!-- end resume -->
    </div>
</template>

<script>
import ResumeAside from '@/components/ResumeAside.vue';

export default {
    name: 'Resume',
    components: {
        ResumeAside
    }
}
</script>

<style lang="scss">

.wrapper {
    position: relative;
}

.sidebar-stripe {
    position: fixed;
    height: 100vh;
    width: 6px;
    background-color: $primary-2-400;
    box-shadow: $depth-2;
}

#homeBtn {
    position: absolute;
    top: 12px;
    left: 0px;
    width: 50px;
    margin-left: 2px;
    height: 40px;
    background-color: $primary-2-400;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resume {
    margin-left: 36px;
    margin-right: 30px;
    padding-top: 70px;
    max-width: 1400px;

    .resume-column {
        min-width: 324px;
        margin-right: 30px;
    }
}

.resume-header {
    margin-bottom: 24px;
    
    h1, h3 {
        color: $neutral-1-050;
    }

    a {
        display: flex;
        align-items: center;

        svg {
            margin-right: 6px;
        }
    }
}

.title-grouping {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    h3, h4 {
        margin-right: 12px;
    }

    h4 {
        color: $neutral-1-100;
    }

    .years {
        font-size: 14px;
    }
}

ol.connected-items {
  list-style-type: none;
  margin: 0;
  padding-bottom: 1em;
  margin-top: 12px;

  li.connector {
    position: relative;
    margin: 0;
    padding-bottom: 1em;
    padding-left: 20px;
  }

  // Create line on left
  li.connector:before {
    content:'';
    position: absolute;
    width: 1px;
    top: 0px;
    bottom: 0px;
    left: 5px;
    background-color: $primary-1-200;
    
  }

  // Create bullet -- fill='%2398AEEB' %23 is the code for '#' so this is really just #98AEEB for the fill color
  li.connector::after {
    content: '';
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%2398AEEB' cx='16' cy='16' r='13'%3E%3C/circle%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    top: 6px;
    width: 12px;
    height: 12px;
  }

  // Bring top of line down on first li so it doesn't go too high
  li.connector:first-child:before { top: 15px;  }

  // Bring bottom of line down on last li so it doesn't go too low
  li.connector:last-child:before { height: 9px; }

  li.inner-connector {
      margin-left: 6px;
  }

  .connected-items-sub-list {
      list-style-type: none;

      ul {
          list-style-type: disc;

          li {
              margin-left: 22px;
              margin-top: 4px;
          }
      }
  }
}

.aside {

    &.hidden-sm {
        display: none;
    }

    &.hidden-lg {
        display: block;
    }

    .group {
        margin-bottom: 12px;
        position: relative;
        padding-bottom: 30px;

        &.bottom-border::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            background-color: $neutral-1-200;
            width: 150px;
        }

        h2 {
            margin-bottom: 12px;

            &.education {
                margin-bottom: 24px;
            }
        }

        h3 {
            color: $neutral-1-100;
        }

        .display-block {
            display: block;
        }
    }
}

// Media Queries for larger sizes
@media screen and (min-width: 1026px) {

    .sidebar-stripe {
        width: 30px;
    }

    #homeBtn {
        width: 60px;
        left: 25px;
    }
    
    .resume {
        display: flex;
        margin-left: 70px;
    }

    .aside {

        &.hidden-sm {
            display: block;
        }

    &.hidden-lg {
        display: none;
    }
    }

}

</style>