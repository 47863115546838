<template>
  <div class="aside margin-top-24">
    <div class="group bottom-border">
        <h2>Contact</h2>
        <a href="https://www.linkedin.com/in/lance-anderson-3780b7a/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-linkedin margin-right-6" width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3AE7E1" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <rect x="4" y="4" width="16" height="16" rx="2" />
                <line x1="8" y1="11" x2="8" y2="16" />
                <line x1="8" y1="8" x2="8" y2="8.01" />
                <line x1="12" y1="16" x2="12" y2="11" />
                <path d="M16 16v-3a2 2 0 0 0 -4 0" />
            </svg>
            <span>LinkedIn</span>
        </a>
    </div><!-- end group -->
    <div class="group bottom-border">
        <h2 class="education">Education</h2>
        <div class="title-grouping">
            <h3>The University of Akron</h3>
            <span class="years">(2002 - 2007)</span>
        </div><!-- end title-grouping -->
        <span class="display-block">Bachelor of Arts in Management: Information Systems</span>
        <span class="display-block">Bachelor of Arts in Management: E-Business Technologies</span>
        <span class="display-block">Associate of Applied Business in Computer Information Systems</span>
    </div><!-- end group -->
    <div class="group bottom-border">
        <h2>Skills</h2>
        <p class="margin-top-12">Fullstack Web Development, UI Design, UX Design, CSS Animations, SVG Animations, Strategic Thinking, Leadership, Project Management</p>
    </div><!-- end group -->
    <div class="group">
        <h2>Tools</h2>
        <p>HTML, CSS, SASS, JavaScript, jQuery, VueJS, Node.js, Express, PostgreSQL, Amazon Web Services (AWS), Azure Cloud Services, Git, SVG, Bootstrap, Google Material Design, SharePoint, XML, JSON, Informatica Power Center, Axure, Balsamiq, Adobe Photoshop, Adobe Illustrator, Sketch, Figma, MS Team Foundation Server (TFS), Azure Dev Ops, Amazon Web Services (AWS)</p>
    </div><!-- end group -->
</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

svg.margin-right-6 {
    margin-right: 6px;
}

</style>